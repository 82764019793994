﻿html,
body {
    margin: 0;
    padding: 0;
}

.hero {
    display: grid;
    position: relative;
    grid-template-columns: 100vw;
    grid-template-rows: 100vh;
    place-items: center;
    overflow: hidden;
    animation: clip-hero-anim 1.25s cubic-bezier(0.29, 0.8, 0.8, 0.98);
    will-change: clip-path;

    &__bg,
    &__cnt {
        align-self: center;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }
    // Images
    &__bg {
        display: grid;
        position: relative;
        z-index: 0;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        place-items: center;
        animation: fade-in 0.75s linear;
        will-change: opacity;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(120deg,#CCD2DE,#001E5E);
            opacity: .7;
            z-index: 5;
        }


        & picture {
            display: flex;
            height: 100vh;
            width: 100vw;
            animation: scaling-hero-anim 4s 0.25s cubic-bezier(0, 0.71, 0.4, 0.97) forwards;
            will-change: transform;
        }

        & img {
            display: block;
            object-fit: cover;
            /*object-position: 77% 50%;*/
            height: auto;
            width: 100%;
        }
    }

    &__cnt {
        display: grid;
        position: relative;
        place-items: center;
        z-index: 10;
        color: $hero-color;
        font-size: 2.5vw;
        text-transform: uppercase;
        opacity: 0;
        animation: fade-in 0.75s 1.5s linear forwards;



        & img {
            height: 70vw;
            width: auto;

            @media(min-width: $min-desktop) {
                height: 30vw;
            }
        }

        & svg {
            height: 12vw;

            & path {
                fill: #FFF;
            }
        }

        & h1 {
            font-family: $paytoneOne;
            margin-bottom: 0;
            color: $white;
            padding: 15px;
        }
    }

    &__indicator {
        position: relative;
        width: 25px;
        height: 25px;
        transform: rotate(45deg);
        margin-top: 65px;

        span {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: none;
            border-bottom: 3px solid #FFF;
            border-right: 3px solid #FFF;
            animation: animate 1s linear infinite;

            &:nth-child(1) {
                top: -30px;
                left: -30px;
                animation-delay: 0s;
            }

            &:nth-child(2) {
                top: -15px;
                left: -15px;
                animation-delay: 0.2s;
            }

            &:nth-child(3) {
                top: 0;
                left: 0;
                animation-delay: 0.4s;
            }

            &:nth-child(4) {
                top: 15px;
                left: 15px;
                animation-delay: 0.6s;
            }

            &:nth-child(5) {
                top: 30px;
                left: 30px;
                animation-delay: 0.8s;
            }
        }
    }
}

// ## Animation
@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scaling-hero-anim {
    from {
        transform: scale(1.25);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes clip-hero-anim {
    from {
        clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
    }

    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

@keyframes animate {
    0% {
        border-color: #FFF;
        transform: translate(0,0);
    }

    20% {
        border-color: #FFF;
        transform: translate(15px,15px);
    }

    20.1%,100% {
        border-color: #001E5E;
    }
}
