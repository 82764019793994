html,
body {
    min-height: 100%;
    scroll-behavior: smooth;
}

body {
  position: relative;
  width: 100%;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

a {
  color: $accent;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}


img {
  display: block; // gets rid of random extra space below images on chrome
  border: 0;
  transition: filter .5s ease-out // transition for .blur effect
}

img.cover { // JH - should we have an object-fit polyfill in the solution for supporting IE?
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// Setting a base size on svg images helps with CLS & flashes of giant images
svg {
  width: 200px;
  height: 200px;
}

// Hide SVG Sprite
.svg-sprite {
  display: none;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.main {
  overflow: hidden;
}


section {
  margin: $vertical-space-mob auto;
  

  @media(min-width: $min-tablet) {
    margin: $vertical-space auto;
  }
}

.container {
  width: 100%;
  max-width: $max-content-width;
  margin: 0 auto; 
  padding: 0 $horizontal-space-mob;
  position: relative;
}

.blur {
  filter: blur(10px);
}

.hidden {
  display: none;
}

.visually-hidden:not(:focus):not(:active) { //hide things visually, but not from screen readers, or people tabbing through the page
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.skip-link {
  background: $accent;
  color: $accent-text;
  font-weight: 700;
  left: 50%;
  padding: 4px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 99999;
}

.skip-link:focus {
  transform: translateY(0%);
}