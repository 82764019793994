html,
%html {
  @include font-size($base-font-size);
  font-family: $regular;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5rem;
  color: $text;
  -webkit-font-smoothing: antialiased;
}

p {
  margin-bottom: $spacing-small;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
p, ul, ol {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $bold;
  color: $titles;
}

h1, .h1 {
  @include font-size(30);
  margin-bottom: 2rem;
}

h2, .h2 {
  @include font-size(24);
  margin-bottom: 1.5rem;
}

h3, .h3 {
  @include font-size(22);
}

h4, .h4 {
  @include font-size(20);
}

@media(min-width: $min-tablet) {

  h1, .h1 {
    @include font-size(50);
    margin-bottom: 2rem;
  }

  h2, .h2 {
    @include font-size(32);
    margin-bottom: 1.5rem;
  }

  h3, .h3 {
    @include font-size(26);
  }

  h4, .h4 {
    @include font-size(20);
  }

}